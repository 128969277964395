html {
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 1em;
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
